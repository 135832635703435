// This Kameleoon loader part is for Anti-flicker https://developers.kameleoon.com/web-experimentation/simple-implementation/#asynchronous-tag-with-anti-flicker
const styleElement = document.getElementById('kameleoonLoadingStyleSheet');
if (styleElement) {
  const invisibilityMeasure = 'kameleoon-page-invisibility';
  const invisibilityStartMarker = `${invisibilityMeasure}-start`;
  const invisibilityEndMarker = `${invisibilityMeasure}-end`;

  const displayPage = () => {
    performance.mark(invisibilityEndMarker);
    performance.measure(invisibilityMeasure, invisibilityStartMarker, invisibilityEndMarker);
    styleElement.remove();
  };

  performance.mark(invisibilityStartMarker);

  window.kameleoonDisplayPageTimeOut = setTimeout(displayPage, styleElement.dataset.timeout); // in case of Kameleoon take to much time, show the page
  window.kameleoonDisplayPage = displayPage;
}

window.kameleoonStartLoadTime ??= Date.now();
